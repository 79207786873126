import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddEditStrandModalComponent } from "./add-edit-strand-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoaderModule } from "src/app/_components/loader/loader.module";

@NgModule({
  declarations: [AddEditStrandModalComponent],
  imports: [CommonModule, LoaderModule, ReactiveFormsModule, NgbModule],
  entryComponents: [AddEditStrandModalComponent],
  exports: [AddEditStrandModalComponent],
})
export class AddEditStrandModalModule {}
