import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadUploadPagModalComponent } from "./download-upload-pag-modal.component";
import { TableModule } from "primeng/table";

@NgModule({
  declarations: [DownloadUploadPagModalComponent],
  imports: [CommonModule, TableModule],
  exports: [DownloadUploadPagModalComponent],
})
export class DownloadUploadPagModalModule {}
