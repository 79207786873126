import { AuthModel } from "../_modules/auth/_models/auth.model";
import { AddressModel } from "../_modules/auth/_models/address.model";
import { SocialNetworksModel } from "../_modules/auth/_models/social-networks.model";

export class UserModel extends AuthModel {
  _id: string;
  forename: string;
  surname: string;
  full_name: string;
  email: any[];
  last_login: Date;
  global_admin: boolean;
  demo_mode: boolean;
  school: any;
  school_group: any;
  academic_year: any;
  permissions: any[];
  exp: any;
  iat: any;

  setUser(user: any) {
    this._id = user.sub || user._id;
    this.forename = user.forename || "";
    this.surname = user.surname || "";
    this.full_name =
      user.forename && user.surname ? `${user.forename} ${user.surname}` : "";
    this.email = user.email || "";
    this.last_login = user.last_login || new Date();
    this.global_admin = user.global_admin || false;
    this.demo_mode = user.demo_mode || false;
    this.school = user.school || null;
    this.school_group = user.school_group || null;
    this.academic_year = user.academic_year || null;
    this.permissions = user.permissions || [];
    this.exp = user.exp || null;
    this.iat = user.iat || null;
  }
}
