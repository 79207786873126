import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorModalComponent } from "src/app/_modals/error-modal/error-modal.component";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(private modalService: NgbModal) {}
  async handleError(error, retry = true, title = null) {
    console.error(error);
    const modalRef = this.modalService.open(ErrorModalComponent, {
      size: "lg",
    });
    let text = "Unknown Error";
    if (error.error && error.error.message) {
      text = error.error.message;
    }
    modalRef.componentInstance.title = `Error`;
    modalRef.componentInstance.body = `<p><strong>An error has occurred</strong></p> <p>${text}</p>`;
    if (title) {
      modalRef.componentInstance.body = `<p><strong>${title}</strong></p> <p>${text}</p>`;
    }
    modalRef.componentInstance.retry = retry;

    return await modalRef.result;
  }
}
