<div class="modal-header">
  <h4 class="modal-title">Modify Categories</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="view === 'categories'">
      <p-table
        [value]="assessmentCycleCategories"
        [columns]="cols"
        [reorderableColumns]="true"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem"></th>
            <th *ngFor="let col of columns" pReorderableColumn>
              {{ col.header }}
            </th>
            <th class="text-right">
              <a class="btn btn-link" (click)="addCategory()">
                <span class="fa fa-plus text-success"></span>
              </a>
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-index="rowIndex"
        >
          <tr [pReorderableRow]="index">
            <td>
              <span class="pi pi-bars" pReorderableRowHandle></span>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
            <td class="text-right">
              <a
                class="btn btn-link"
                [class.disabled]="!canEdit(rowData)"
                (click)="editCategory(rowData)"
              >
                <span class="fa fa-edit text-primary"></span>
              </a>
              <a
                class="btn btn-link"
                [class.disabled]="!canEdit(rowData)"
                (click)="deleteCategory(rowData)"
              >
                <span class="fa fa-trash text-danger"></span>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-container>
    <ng-container *ngIf="view === 'form'">
      <form [formGroup]="form">
        <div class="form-group">
          <label>Name</label>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            placeholder="Name"
            [class.is-invalid]="formSubmitted && f.name.errors"
          />
          <div *ngIf="formSubmitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
          </div>
        </div>

        <div class="row ml-0 mr-0 justify-content-end">
          <button
            type="button"
            class="btn btn-secondary btn-sm mr-3"
            (click)="submit()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="submitAssessmentCycleCategory()"
          >
            Save
          </button>
        </div>
      </form>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="view != 'form'" class="modal-footer">
  <button type="button" class="btn grey btn-secondary" (click)="close()">
    Close
  </button>
  <button type="button" class="btn btn-success" (click)="saveCategoryOrder()">
    Save
  </button>
</div>
