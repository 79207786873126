import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GeneralModalComponent } from "./general-modal.component";

@NgModule({
  declarations: [GeneralModalComponent],
  imports: [CommonModule],
  exports: [GeneralModalComponent],
  entryComponents: [GeneralModalComponent],
})
export class GeneralModalModule {}
