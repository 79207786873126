import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "../_services/user/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: any = this.authService.currentUserValue;

    if (currentUser) {
      // logged in so return true

      // get the current url
      const currentUrl = state.url;
      // get previous url
      let previousUrl = sessionStorage.getItem("previousUrl");

      // check school-group permissions
      if (
        !currentUser.global_admin &&
        currentUrl.includes("school-group/") &&
        currentUser.school_group
      ) {
        let permissions = currentUser.school_group.permissions;
        // // School Group Edit Group Access
        // if (
        //   currentUrl.includes("school-group/add-edit") &&
        //   !permissions.includes("SchoolGroup.AddEdit")
        // ) {
        //   this.router.navigate(["school/permission-denied"], {
        //     queryParams: { permissionType: "SchoolGroup.AddEdit" },
        //   });
        // }
        // // School Group Assessment Cycle Access
        // if (
        //   currentUrl.includes("school-group/assessment-cycles") &&
        //   !permissions.includes("SchoolGroup.AssessmentCycle")
        // ) {
        //   this.router.navigate(["school/permission-denied"], {
        //     queryParams: {
        //       permissionType: "SchoolGroup.AssessmentCycle",
        //     },
        //   });
        // }
        // // School Group Subjects Access
        // if (
        //   currentUrl.includes("school-group/subjects") &&
        //   !permissions.includes("SchoolGroup.Subjects")
        // ) {
        //   this.router.navigate(["school/permission-denied"], {
        //     queryParams: {
        //       permissionType: "SchoolGroup.Subjects",
        //     },
        //   });
        // }
        // School Group Access
        if (
          currentUrl.includes("select/school") &&
          !permissions.includes("SchoolGroup.MAT.Level.Access")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "SchoolGroup.MAT.Level.Access",
            },
          });
        }
      }

      // check school permissions
      if (
        !currentUser.global_admin &&
        currentUrl.includes("school/") &&
        currentUser.school
      ) {
        let permissions = currentUser.school.permissions;
        // School Documents Access
        if (
          currentUrl.includes("school/admin/documents") &&
          !permissions.includes("School.Documents.Sign")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: { permissionType: "School.Documents.Sign" },
          });
        }
        // School Groups Access
        if (
          currentUrl.includes("school/groups") &&
          !permissions.includes("School.Groups")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.School.Groups",
            },
          });
        }
        // School Students Access
        if (
          currentUrl.includes("/school/students") &&
          !permissions.includes("School.Students")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.Students",
            },
          });
        }
        // School User Management Access
        if (
          currentUrl.includes("school/admin/user-management") &&
          !permissions.includes("School.UserManagement")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.UserManagement",
            },
          });
        }
        // School Academic Year Access
        if (
          currentUrl.includes("school/admin/academic-years") &&
          !permissions.includes("School.AcademicYear.Admin")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.AcademicYear.Admin",
            },
          });
        }
        // School Attendance Access
        if (
          currentUrl.includes("school/attendance") &&
          !permissions.includes("School.Attendance.Read")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.Attendance.Read",
            },
          });
        }
        // School SuperGroups Access Admin
        if (
          currentUrl.includes("school/supergroups") &&
          !currentUrl.includes("attendance") &&
          !permissions.includes("School.Supergroups.Admin")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.Supergroups.Admin",
            },
          });
        }
        // School SuperGroups Access Attendance
        if (
          currentUrl.includes("school/supergroups") &&
          currentUrl.includes("attendance") &&
          !permissions.includes("School.Supergroups")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: {
              permissionType: "School.Supergroups",
            },
          });
        }
        // School Attendance Admin Access
        if (
          currentUrl.includes("school/admin/attendance-admin") &&
          !permissions.includes("School.Attendance.Admin")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: { permissionType: "School.Attendance.Admin" },
          });
        }
        // TODO SCHOOL ASSESSOR AUTHGUARD
        // School Accessor Access
        if (
          currentUrl.includes("school/assessor") &&
          !permissions.includes("School.Subjects") &&
          !permissions.includes("School.Assessments.Read") &&
          !permissions.includes("School.Assessments.Write") &&
          !permissions.includes("School.Subjects.Admin") &&
          !permissions.includes("School.Assessments.Admin") &&
          !permissions.includes("School.AssessmentCycles.Admin") &&
          !permissions.includes("School.Students.Results.Admin")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: { permissionType: "School.Assessments.Admin" },
          });
        }
        // School Documents Access
        if (
          currentUrl.includes("school/documents") &&
          !currentUrl.includes("school/documents-sign") &&
          !permissions.includes("School.Documents.Sign")
        ) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: { permissionType: "School.Documents.Sign" },
          });
        }

        // Groups Admin Access
        if (currentUrl.includes("school/admin/groups-admin")) {
          this.router.navigate(["school/permission-denied"], {
            queryParams: { permissionType: "School.Groups.Admin" },
          });
        }
      }

      // loading school-group page after logging in as a school-group user
      if (
        !currentUser.global_admin &&
        currentUser.school_group &&
        state.url.includes("school/")
      ) {
        this.router.navigate(["/school-group/dashboard"]);
        return true;
      }

      if (
        !currentUser.school &&
        !currentUser.school_group &&
        currentUser.global_admin &&
        (previousUrl === null || !previousUrl.includes("global-admin")) &&
        state.url.includes("school/") &&
        state.url.includes("school-group/") &&
        !state.url.includes("global-admin")
      ) {
        this.router.navigate(["/global-admin/dashboard"]);
        return true;
      }

      //expired session
      if (currentUser.exp < Date.now() / 1000) {
        sessionStorage.removeItem("currentUser");
        sessionStorage.removeItem("remember");
        this.authService.logout({ expired: true });
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    let url = {
      state: state.url,
      error401: true,
    };
    if (state.url.includes("login/auth")) {
      url = null;
    }
    this.authService.logout(url);
    return false;
  }
}
