<div class="modal-header">
  <h5 class="modal-title">
    {{ title }}
  </h5>
  <button type="button" class="close" (click)="close()">&times;</button>
</div>
<div class="modal-body" *ngIf="!(isLoading$ | async)">
  <p-table
    #dt1
    [scrollable]="true"
    [value]="academicYears$ | async"
    [rows]="100"
    scrollHeight="400px"
    [virtualScroll]="true"
    sortField="name"
    sortOrder="1"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Name
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [pSelectableRow]="row">
        <td>
          {{ row.name }}
        </td>

        <td class="text-right">
          <button
            type="button"
            class="btn btn-success btn-sm"
            (click)="selectAcademicYear(row)"
          >
            Select
          </button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="2">No academic years found</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<askeddi-loader *ngIf="isLoading$ | async"></askeddi-loader>
