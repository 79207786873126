import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable } from "rxjs";
import { PermissionService } from "src/app/_services/permission/permission.service";
import { UserService } from "src/app/_services/user/user.service";
import { AuthService } from "src/app/_modules/auth";
import { ErrorService } from "src/app/_services/error/error.service";
import { AcademicYearService } from "../../_services/academic-years/academic-year.service";
import { DateHelperService } from "../../_helpers/date-helper.service";

@Component({
  selector: "app-users-dashboard",
  templateUrl: "./academic-year-selector-modal.component.html",
  styleUrls: [
    "../academic-year-selector/academic-year-selector-modal.component.scss",
  ],
})
export class AcademicYearSelectorModalComponent implements OnInit {
  currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentUser$: Observable<any> = this.currentUserSubject.asObservable();
  currentUser;
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
  academicYearsSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  academicYears$: Observable<any> = this.academicYearsSubject.asObservable();
  academicYears: any = [];
  submitted = false;
  title = "Academic Year Selection";
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    public userService: UserService,
    public academicYearService: AcademicYearService,
    public dateHelper: DateHelperService,
    private errorService: ErrorService // private toastr: ToastrService
  ) {
    this.currentUserSubject.next(this.authService.currentUserValue);
    this.currentUser = this.currentUserSubject.getValue();
  }

  async ngOnInit() {
    // Set Page Title
    this.academicYearsSubject.next(await this.academicYearService.index());
    this.isLoadingSubject.next(false);
  }
  async selectAcademicYear(academicYear) {
    this.submitted = true;
    this.isLoadingSubject.next(true);
    try {
      this.authService.selectAcademicYear(academicYear);
      this.activeModal.close(true);
    } catch (error) {
      const doRetry = await this.errorService.handleError(error, true);
      if (doRetry) {
        await this.selectAcademicYear(academicYear);
      }
    } finally {
      this.submitted = false;
      this.isLoadingSubject.next(false);
    }
  }

  get f() {
    return this.form.controls;
  }
  public close() {
    this.activeModal.dismiss();
  }
}
