import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadUploadTargetGradesModalComponent } from "./download-upload-target-grades-modal.component";
import { TableModule } from "primeng/table";

@NgModule({
  declarations: [DownloadUploadTargetGradesModalComponent],
  imports: [CommonModule, TableModule],
  exports: [DownloadUploadTargetGradesModalComponent],
})
export class DownloadUploadTargetGradesModalModule {}
