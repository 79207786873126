import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-document-modal",
  templateUrl: "./error-modal.component.html",
  styleUrls: ["./error-modal.component.css"],
})
export class ErrorModalComponent implements OnInit {
  public title: any;
  public body: any;
  public retry: boolean = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  submit() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
