import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.css"],
})
export class DeleteModalComponent implements OnInit {
  public name = "";
  public message = "Are you sure?";
  public closeButtonText = "Close";
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  submit() {
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
