import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class SchoolGroupService {
  constructor(private http: HttpClient) {}

  /**
   * Get all school groups on system
   */
  async index(model = null) {
    return await this.http
      .post<any>(`${environment.api.url}/school-groups`, model)
      .toPromise();
  }

  async show(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/school-groups/school-group/${id}`)
      .toPromise();
  }

  getCurrentAcademicYear(schoolGroupID) {
    return this.http
      .post(`${environment.api.url}/school-groups/current-academic-year`, {
        school_group: schoolGroupID,
      })
      .toPromise();
  }

  async add(model: any) {
    return this.http
      .post(`${environment.api.url}/school-groups/school-group/add`, model)
      .toPromise();
  }

  async edit(id, model: any) {
    return this.http
      .post(`${environment.api.url}/school-groups/school-group/${id}`, model)
      .toPromise();
  }

  async delete(id: string) {
    return this.http
      .post(
        `${environment.api.url}/school-groups/school-group/${id}/delete`,
        null
      )
      .toPromise();
  }

  async getStudentResultsInAcademicYear(id: string, type: string = "ks1") {
    return this.http
      .get<any>(
        `${environment.api.url}/school-groups/years/${id}/student-results/${type}`
      )
      .toPromise();
  }

  async getTargetGradesInAcademicYear(groupID: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/school-groups/years/${groupID}/target-grades`
      )
      .toPromise();
  }

  async getTargetGradesInAcademicYearBasic(studentIDs) {
    return this.http
      .post(
        `${environment.api.url}/school-groups/years/target-grades-basic`,
        studentIDs
      )
      .toPromise();
  }

  async getEddiSubjectsByID(IDs) {
    const post = {
      eddiSubjects: IDs,
    };
    return this.http
      .post(`${environment.api.url}/school-groups/subjects/by-id`, post)
      .toPromise();
  }
  async schoolGroupSchools(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/school-groups/school-group/${id}/schools`
      )
      .toPromise();
  }
  async getBySchool(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/school-groups/school-group/${id}/get-by-school`
      )
      .toPromise();
  }

  async activeProducts(id) {
    const products: any = await this.http
      .get(
        `${environment.api.url}/school-groups/school-group/${id}/products/active`
      )
      .toPromise();
    for (const product of products) {
      if (product.start_date) {
        product.start_date = DateTime.fromISO(product.start_date).toJSDate();
      }
    }
    return products;
  }
}
