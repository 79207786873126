import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ClipboardModule } from "ngx-clipboard";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg";
import { ToastrModule } from "ngx-toastr";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./_services/user/auth.service";
import { environment } from "src/environments/environment";
// Highlight JS
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";
import { SplashScreenModule } from "./_metronic/partials/layout/splash-screen/splash-screen.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { MsalModule } from "@azure/msal-angular";
import { GeneralModalModule } from "./_modals/general-modal/general-modal.module";
import { AcademicYearSelectorModalModule } from "./_modals/academic-year-selector/academic-year-selector-modal.module";
import { JwtModule } from "@auth0/angular-jwt";
import { LuxonDateFormatter } from "./_helpers/date-format";
import {
	SocialLoginModule,
	GoogleLoginProvider,
	SocialAuthServiceConfig,
} from "angularx-social-login";
import { AddEditStrandModalModule } from "./pages/subjects/strands/add-edit-strand-modal/add-edit-strand-modal.module";

export function tokenGetter() {
	let token = JSON.parse(
		sessionStorage.getItem(
			`${environment.appVersion}-${environment.USERDATA_KEY}`
		)
	);
	return token && token.authToken ? token.authToken : null;
}
import { CommonModule } from "@angular/common";
import { ModifyAssessmentCycleCategoriesModalModule } from "./_modals/modify-assessment-cycle-categories-modal/modify-assessment-cycle-categories-modal.module";
import { DownloadUploadPagModalModule } from "./_modals/download-upload-pag-modal/download-upload-pag-modal.module";
import { DownloadUploadTargetGradesModalModule } from "./_modals/download-upload-target-grades-modal/download-upload-target-grades-modal.module";
import { DownloadUploadMarkbookScoresModalModule } from "./_modals/download-upload-markbook-scores-modal/download-upload-markbook-scores-modal.module";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

function appInitializer() {
	return () => {};
}

const isIE =
	window.navigator.userAgent.indexOf("MSIE ") > -1 ||
	window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		ToastrModule.forRoot(),
		BrowserAnimationsModule,
		SplashScreenModule,
		CommonModule,
		TranslateModule.forRoot(),
		HttpClientModule,
		ClipboardModule,
		AppRoutingModule,
		InlineSVGModule.forRoot(),
		NgbModule,
		FontAwesomeModule,
		AcademicYearSelectorModalModule,
		ModifyAssessmentCycleCategoriesModalModule,
		JwtModule.forRoot({
			config: {
				tokenGetter,
				allowedDomains: [
					"localhost:3200",
					"api.staging.askeddi.com",
					"api.askeddi.com",
				],
				disallowedRoutes: [
					"localhost:3200/login",
					"api.staging.askeddi.com/login",
					"api.askeddi.com/login",
				],
			},
		}),
		MsalModule.forRoot(
			{
				auth: {
					// clientId: 'Enter_the_Application_Id_here', // This is your client ID
					clientId: environment.msal.clientId,
					authority: "https://login.microsoftonline.com/common", // This is your tenant ID
					// redirectUri: 'Enter_the_Redirect_Uri_Here'// This is your redirect URI
				},
				cache: {
					cacheLocation: "localStorage",
					storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
				},
			},
			{
				popUp: !isIE,
				consentScopes: ["user.read", "openid", "profile"],
				unprotectedResources: [],
				protectedResourceMap: [
					["https://graph.microsoft.com/v1.0/me", ["user.read"]],
				],
				extraQueryParameters: {},
			}
		),
		GeneralModalModule,
		SocialLoginModule,
		AddEditStrandModalModule,
		DownloadUploadPagModalModule,
		DownloadUploadTargetGradesModalModule,
		DownloadUploadMarkbookScoresModalModule,
	],

	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthService],
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: {
				coreLibraryLoader: () => import("highlight.js/lib/core"),
				languages: {
					xml: () => import("highlight.js/lib/languages/xml"),
					typescript: () => import("highlight.js/lib/languages/typescript"),
					scss: () => import("highlight.js/lib/languages/scss"),
					json: () => import("highlight.js/lib/languages/json"),
				},
			},
		},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{
			provide: "SocialAuthServiceConfig",
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.google.clientId),
					},
				],
			} as SocialAuthServiceConfig,
		},
		{
			provide: NgbDateParserFormatter,
			useFactory: () => new LuxonDateFormatter(),
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
