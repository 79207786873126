import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {
  faHome,
  faUsersClass,
  faChartLine,
  faChartPie,
  faChartBar,
  faChartScatter,
  faUsersCog,
  faClipboardListCheck,
  faFileAlt,
  faBookReader,
  faDownload,
  faQuestionCircle,
  faShoppingCart,
  faCity,
  faUniversity,
  faListAlt,
  faLayerPlus,
  faCode,
  faUserLock,
  faCreditCard,
  faLink,
  faFlag,
  faPoundSign,
  faEye,
  faGraduationCap,
  faUserCog,
  faUsers,
  faSchool,
  faLayerGroup,
  faFolderTree,
  faCalendar,
  faCalendarPlus,
  faCheckCircle,
  faSkullCrossbones,
  faQuestionSquare,
  faInfoCircle,
  faTachometer,
  faBell,
  faSuitcase,
} from "@fortawesome/pro-duotone-svg-icons";

export interface MenuItem {
  title: string;
  icon: any;
  page: string;
  root: boolean;
  link;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  isStarterkitExternalLink?: boolean;
  badge: { type: string; value: string };
  // submenu: {
  //   items: Partial<MenuItem>[];
  // };
  submenu: Partial<MenuItem>[];
  section: string;
  category: string;
}

export interface MenuConfig {
  global_admin: {
    items: Partial<MenuItem>[];
  };
  school: {
    items: Partial<MenuItem>[];
  };
  school_group: {
    items: Partial<MenuItem>[];
  };
}

export const DynamicAsideMenuConfig: MenuConfig = {
  global_admin: {
    items: [
      {
        title: "Home",
        root: true,
        icon: faHome,
        page: "/global-admin/dashboard",
      },
      {
        title: "Client Admin",
        root: true,
        submenu: [
          {
            title: "Schools",
            icon: faGraduationCap,
            page: "/global-admin/schools",
          },
          {
            title: "School Groups",
            icon: faGraduationCap,
            page: "/global-admin/school-groups",
          },
        ],
      },
      {
        title: "Product Admin",
        root: true,
        submenu: [
          {
            title: "Products",
            root: true,
            icon: faPoundSign,
            page: "/global-admin/products",
          },
        ],
      },
      // {
      //   title: "Dev",
      //   root: true,
      //   submenu: [
      //     {
      //       title: "Dev",
      //       root: true,
      //       icon: faPoundSign,
      //       page: "/global-admin/dev",
      //     },
      //   ],
      // },
      {
        title: "User Admin",
        root: true,
        submenu: [
          {
            title: "Phase of Educations",
            root: true,
            icon: faUserCog,
            page: "/global-admin/phase-of-educations",
          },
          {
            title: "Permissions",
            root: true,
            icon: faUserCog,
            page: "/global-admin/permissions",
          },
          {
            title: "Roles",
            root: true,
            icon: faUserCog,
            page: "/global-admin/roles",
          },
          {
            title: "User Management",
            root: true,
            icon: faUsers,
            page: "/global-admin/admin/user-management",
          },
        ],
      },
      {
        title: "Assessor Admin",
        root: true,
        submenu: [
          {
            title: "Assessment Cycles",
            root: true,
            icon: faUsersClass,
            page: "/global-admin/assessment-cycles",
          },
          {
            title: "Test Collection Types",
            root: true,
            icon: faUsers,
            page: "/global-admin/test-collection-types",
          },
          {
            title: "Subjects",
            root: true,
            icon: faPoundSign,
            page: "/global-admin/subjects",
          },
          {
            title: "Subject Types",
            root: true,
            icon: faPoundSign,
            page: "/global-admin/subject-types",
          },
          {
            title: "Assessment Cycle Types",
            root: true,
            icon: faUsers,
            page: "/global-admin/assessment-cycle-types",
          },
          {
            title: "Assessor Test Types",
            root: true,
            icon: faUsers,
            page: "/global-admin/assessor-test-types",
          },
          {
            title: "Score Band Levels",
            root: true,
            icon: faUsers,
            page: "/global-admin/score-band-levels",
          },
        ],
      },
      {
        title: "General Admin",
        root: true,
        submenu: [
          {
            title: "Presets",
            root: true,
            icon: faUsers,
            page: "/global-admin/presets",
          },
        ],
      },
    ],
  },
  school: {
    items: [
      // {
      //   title: "Home",
      //   root: true,
      //   icon: faHome,
      //   page: "/school/dashboard",
      // },
      {
        title: "Home",
        root: true,
        icon: faHome,
        page: "/school/dashboard",
      },

      { section: "Attendance", category: "Attendance" },
      {
        title: "Attendance Dashboard",
        root: true,
        icon: faChartBar,
        page: "/school/attendance-dashboard",
      },
      {
        title: "PA Dashboard",
        root: true,
        icon: faChartScatter,
        page: "/school/attendance-pa-dashboard",
      },
      {
        title: "All Years Overview",
        root: true,
        icon: faChartPie,
        page: "/school/attendance/overview",
      },
      {
        title: "School",
        root: true,
        icon: faSchool,
        page: "/school/home",
        category: "Attendance",
      },
      { section: "Groups", category: "Attendance" },
      {
        title: "Registration",
        root: true,
        icon: faLayerGroup,
        page: "/school/groups/registration",
        category: "Attendance",
      },
      {
        title: "Years",
        root: true,
        icon: faGraduationCap,
        page: "/school/groups/years",
        category: "Attendance",
      },
      { section: "Assessor", category: "Assessor" },
      {
        title: "Assessor Dashboard",
        root: true,
        icon: faTachometer,
        page: "/school/assessor-dashboard",
        category: "Assessor",
      },
      {
        title: "Assessment Cycles",
        root: true,
        icon: faUsersClass,
        page: "/school/assessment-cycles",
        category: "Assessor",
      },
      {
        title: "Progress Over Time",
        root: true,
        icon: faChartLine,
        page: "/school/progress-over-time-report",
        category: "Assessor",
      },
      {
        title: "Subjects",
        root: true,
        icon: faBookReader,
        page: "/school/subjects",
        category: "Assessor",
      },

      // {
      //   title: "All Groups",
      //   root: true,
      //   icon: faHome,
      //   page: "/school/dashboard",
      // },

      { section: "Admin" },
      {
        title: "Academic Years",
        root: true,
        icon: faCalendarPlus,
        page: "/school/admin/academic-years",
      },
      {
        title: "Attendance Admin",
        root: true,
        icon: faClipboardListCheck,
        page: "/school/admin/attendance-admin",
      },
      // {
      //   title: "Documents",
      //   root: true,
      //   icon: faUsers,
      //   page: "/school/admin/documents",
      // },
      {
        title: "Groups Admin",
        root: true,
        icon: faFolderTree,
        page: "/school/admin/groups-admin",
      },
      // {
      //   title: "School Groups",
      //   root: true,
      //   icon: faUsers,
      //   page: "/school/admin/school-groups",
      // },
      {
        title: "User Management",
        root: true,
        icon: faUsers,
        page: "/school/admin/user-management",
      },
      { section: "Help" },
      {
        title: "User Guides ",
        icon: faInfoCircle,
        page: "https://hub.askeddi.com/knowledge-base/",
        isExternalLink: true,
      },
      {
        title: "Support",
        icon: faQuestionCircle,
        page: "https://askeddi.com/contact-us/",
        isExternalLink: true,
      },
    ],
  },

  school_group: {
    items: [
      {
        title: "Home",
        root: true,
        icon: faHome,
        page: "/school-group/dashboard",
      },
      { section: "Attendance" },
      {
        title: "Attendance Dashboard",
        root: true,
        icon: faChartBar,
        page: "/school-group/attendance-dashboard",
      },
      { section: "Assessor" },
      {
        title: "Assessor Dashboard",
        root: true,
        icon: faTachometer,
        page: "/school-group/assessor-dashboard",
      },
      {
        title: "Assessment Cycles",
        root: true,
        icon: faUsersClass,
        page: "/school-group/assessment-cycles",
      },
      {
        title: "Subjects",
        root: true,
        icon: faBookReader,
        page: "/school-group/subjects",
      },
      { section: "Admin" },
      {
        title: "Edit Group",
        root: true,
        icon: faFolderTree,
        page: "/school-group/add-edit",
      },
      {
        title: "Academic Years",
        root: true,
        icon: faCalendarPlus,
        page: "/school-group/admin/academic-years",
      },
      {
        title: "User Management",
        root: true,
        icon: faUsers,
        page: "/school-group/admin/user-management",
      },
    ],
  },
};
