<div class="modal-header">
  <h4 class="modal-title">Delete: {{ name }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="message"></div>
<div class="modal-footer">
  <button type="button" class="btn grey btn-secondary" (click)="close()">
    {{ closeButtonText }}
  </button>
  <button type="button" class="btn btn-danger" (click)="submit()">
    Delete
  </button>
</div>
