import { Injectable, OnDestroy } from "@angular/core";
import { Observable, BehaviorSubject, of, Subscription } from "rxjs";
import { map, catchError, switchMap, finalize } from "rxjs/operators";
import { UserModel } from "../../_models/user.model";
import { AuthModel } from "../../_modules/auth/_models/auth.model";
// import { AuthHTTPService } from "./auth-http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { DynamicAsideMenuService } from "src/app/_metronic/core";
import { HttpClient } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AcademicYearService } from "../academic-years/academic-year.service";
import { SchoolService } from "../school/school.service";
import { SchoolGroupService } from "../school-group/school-group.service";
import { AttendanceService } from "../attendance/attendance.service";
import { DateHelperService } from "src/app/_helpers/date-helper.service";
@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  helper = new JwtHelperService();

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    if (user) {
      sessionStorage.setItem("current_user", JSON.stringify(user));
    }
    this.currentUserSubject.next(user);
  }

  constructor(
    // private authHttpService: AuthHTTPService,
    private router: Router,
    private dynamicAsideMenuService: DynamicAsideMenuService,
    private http: HttpClient,
    private academicYearService: AcademicYearService,
    private schoolService: SchoolService,
    private schoolGroupService: SchoolGroupService, // private attendanceService: AttendanceService
    public dateHelper: DateHelperService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    const currentUserValue = sessionStorage.getItem("current_user")
      ? JSON.parse(sessionStorage.getItem("current_user"))
      : null;
    this.currentUserSubject = new BehaviorSubject<UserModel>(currentUserValue);
    this.currentUserValue = currentUserValue;
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // const subscr = this.getUserByToken();
    // this.unsubscribe.push(subscr);
  }

  async microsoftLogin(idToken) {
    return await this.http
      .get<any>(`${environment.api.url}/auth/microsoft/login`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }

  async googleLogin(idToken) {
    return await this.http
      .get<any>(`${environment.api.url}/auth/google/login`, {
        headers: { Authorization: `Bearer ${idToken}` },
      })
      .toPromise();
  }

  async userContextPermissions(model: any) {
    return await this.http
      .post(environment.api.url + "/auth/permissions", model)
      .toPromise();
  }

  // login(email: string, password: string): Observable<UserModel> {
  async login(token) {
    this.isLoadingSubject.next(true);
    const decodedToken = this.helper.decodeToken(token.access_token);
    const authModel = new AuthModel();
    authModel.setAuth({
      authToken: token.access_token,
      expires: decodedToken.exp,
    });
    this.setAuthFromLocalStorage(authModel);
    let routerLink = null;

    if (decodedToken.school) {
      routerLink = "/school/dashboard";
    }
    if (decodedToken.school_group) {
      routerLink = "/school-group/dashboard";
    }

    this.setUserByToken(decodedToken);
    this.dynamicAsideMenuService.loadMenu();
    this.isLoadingSubject.next(false);
    if (decodedToken.global_admin) {
      routerLink = "/global-admin/dashboard";
    }
    this.router.navigate([routerLink]);
  }

  logout(url = null) {
    sessionStorage.clear();
    this.currentUserValue = undefined;
    this.router.navigate(["/auth/login"], {
      queryParams: url,
    });
  }

  // getUserByToken(): Observable<UserModel> {
  setUserByToken(token) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const user = new UserModel();
    user.setUser(token);
    this.currentUserValue = user;
    this.isLoadingSubject.next(false);
    return user;
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      sessionStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        sessionStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async setGlobalAdmin() {
    const user = new UserModel();
    this.currentUserValue.school = null;
    this.currentUserValue.school_group = null;
    this.currentUserValue.academic_year = null;
    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    sessionStorage.setItem("current_user", JSON.stringify(user));
    this.dynamicAsideMenuService.loadMenu();
    this.router.navigate(["/global-admin/dashboard"]);
  }

  async selectSchool(school) {
    const user = new UserModel();
    this.currentUserValue.school_group = null;
    this.currentUserValue.school = {
      _id: school._id,
      name: school.name,
      phase_of_education: school.phase_of_education,
      school_sync_last_date_completed: school.school_sync_last_date_completed,
      permissions: await this.userContextPermissions({
        userID: this.currentUserValue._id,
        context: "school",
        contextID: school._id,
      }),
      products: await this.schoolService.activeProducts(school._id),
    };
    this.currentUserValue.academic_year = await this.schoolService.getCurrentAcademicYear(
      school._id
    );

    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    this.dynamicAsideMenuService.loadMenu();
    sessionStorage.removeItem("attendance_preset");

    let href = "/school/dashboard";

    if (this.currentUserValue.school_group) {
      href = "/school-group/dashboard";
    }

    window.location.href = href;
  }

  async selectAcademicYear(academicYear) {
    const user = new UserModel();
    this.currentUserValue.academic_year = academicYear;
    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    this.dynamicAsideMenuService.loadMenu();
    sessionStorage.removeItem("attendance_preset");

    let href = "/school/dashboard";

    if (this.currentUserValue.school_group) {
      href = "/school-group/dashboard";
    }

    window.location.href = href;
  }

  async selectSchoolGroup(schoolGroup) {
    const user = new UserModel();
    this.currentUserValue.school_group = {
      _id: schoolGroup._id,
      name: schoolGroup.name,
      permissions: schoolGroup.permissions,
    };
    this.currentUserValue.school_group.permissions = await this.userContextPermissions(
      {
        userID: this.currentUserValue._id,
        context: "school_group",
        contextID: this.currentUserValue.school_group._id,
      }
    );
    (this.currentUserValue.school_group.products = await this.schoolGroupService.activeProducts(
      schoolGroup._id
    )),
      (this.currentUserValue.academic_year = await this.schoolGroupService.getCurrentAcademicYear(
        schoolGroup._id
      ));

    this.currentUserValue.school = null;

    user.setUser(this.currentUserValue);
    this.currentUserValue = user;
    this.dynamicAsideMenuService.loadMenu();
    sessionStorage.removeItem("attendance_preset");

    let href = "/school-group/dashboard";

    window.location.href = href;
    // this.router.navigate(["/school-group/dashboard"]);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  hasProduct(productCode, type = "school") {
    const currentUser = this.currentUserValue;
    if (currentUser && currentUser[type] && currentUser[type].products) {
      for (const product of currentUser[type].products) {
        const pattern = `${productCode}`;
        if (product.code.match(new RegExp(pattern, "gi"))) {
          return true;
        }
      }
    }
    return false;
  }

  productInDate(productCode, type = "school") {
    const currentUser = this.currentUserValue;
    const date = new Date();
    if (currentUser && currentUser[type] && currentUser[type].products) {
      for (const product of currentUser[type].products) {
        if (product.start_date) {
          product.start_date = this.dateHelper.toJSDate(product.start_date);
        }
        if (product.end_date) {
          product.end_date = this.dateHelper.toJSDate(product.end_date);
        }
        const pattern = `${productCode}`;
        if (
          product.start_date &&
          product.end_date &&
          date > product.start_date &&
          date < product.end_date &&
          product.code.match(new RegExp(pattern, "gi"))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  newLogin(value) {
    return this.http
      .post(environment.api.url + "/auth/login", value)
      .toPromise();
  }
}
