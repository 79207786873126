import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class UserService {
	constructor(private http: HttpClient) {}

	/**
	 * Get all schools linked to logged in user
	 */
	async schools() {
		return await this.http
			.get<any>(`${environment.api.url}/user/schools`)
			.toPromise();
	}

	editPassword(userID, password) {
		return this.http
			.post(environment.api.url + "/user/update-password", {
				user: userID,
				password: password,
			})
			.toPromise();
	}

	/**
	 * Get all school groups linked to logged in user
	 */
	async schoolGroups() {
		return await this.http
			.get<any>(`${environment.api.url}/user/school-groups`)
			.toPromise();
	}

	/**
	 * Get all users on system
	 */
	async index() {
		return await this.http.get<any>(`${environment.api.url}/users`).toPromise();
	}

	/**
	 * Get users according to the level of access
	 */

	getUsers() {
		return this.http
			.get<any>(`${environment.api.url}/user/all-users`)
			.toPromise();
	}

	checkUserExists(email: any) {
		return this.http
			.get(environment.api.url + "/user/check-user-exists/" + email)
			.toPromise();
	}

	single(id: string) {
		return this.http.get(environment.api.url + "/user/user/" + id).toPromise();
	}

	add(model: any) {
		return this.http
			.post(environment.api.url + "/user/user", model)
			.toPromise();
	}

	edit(id: any, model: any) {
		return this.http
			.post(environment.api.url + "/user/user/" + id, model)
			.toPromise();
	}

	delete(id: string, model: any) {
		return this.http
			.post(environment.api.url + "/user/user/" + id + "/delete", model)
			.toPromise();
	}

	/* Email Check */
	checkEmailTaken(email: any) {
		return this.http
			.post(environment.api.url + "/user/check-email-taken", { email: email })
			.pipe(
				map((res) => {
					return res ? true : null;
				})
			)
			.toPromise();
	}

	deleteEmail(userID: any, email: any) {
		return this.http
			.post(environment.api.url + "/user/delete-email", {
				userID: userID,
				email: email,
			})
			.pipe(
				map((res) => {
					return res ? true : null;
				})
			)
			.toPromise();
	}

	/* Setting Default */
	async setDefault(userID, schoolID = null, schoolGroupID = null) {
		return await this.http
			.post(environment.api.url + "/user/set-default", {
				user: userID,
				school: schoolID,
				schoolGroup: schoolGroupID,
			})
			.toPromise();
	}

	/* Get School Employee */
	async getSchoolEmployee(schoolID) {
		return await this.http
			.post(`${environment.api.url}/user/school-employees`, {
				school: schoolID,
			})
			.toPromise();
	}
}
