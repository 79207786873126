import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class SchoolService {
  constructor(private http: HttpClient) {}

  /**
   * Get all schools on system
   */
  async index() {
    return await this.http
      .get<any>(`${environment.api.url}/schools`)
      .toPromise();
  }

  async getSchoolGroupSchools() {
    return await this.http
      .get<any>(`${environment.api.url}/schools/school-group-schools`)
      .toPromise();
  }

  async getWondeSchools() {
    return await this.http
      .get<any>(`${environment.api.url}/schools/get-wonde-schools`)
      .toPromise();
  }

  async addSchool(school) {
    return this.http
      .post(`${environment.api.url}/schools/add-school`, { id: school })
      .toPromise();
  }

  getCurrentAcademicYear(schoolID) {
    return this.http
      .post(`${environment.api.url}/schools/current-academic-year`, {
        school: schoolID,
      })
      .toPromise();
  }

  async searchSchools(data: any, find = {}) {
    data.find = find;
    return await this.http
      .post(environment.api.url + "/schools/search-schools", data)
      .toPromise();
  }

  async show(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/schools/school/${id}`)
      .toPromise();
  }

  async getLastSynchedDate(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/schools/school/${id}/last-synched-date`)
      .toPromise();
  }

  async add(model: any) {
    return this.http.post(`${environment.api.url}/schools`, model).toPromise();
  }

  async edit(id, model: any) {
    return this.http
      .post(`${environment.api.url}/schools/school/${id}`, model)
      .toPromise();
  }

  async delete(id: string) {
    return this.http
      .post(`${environment.api.url}/schools/school/${id}/delete`, null)
      .toPromise();
  }

  async activeProducts(id) {
    const products: any = await this.http
      .get(`${environment.api.url}/schools/school/${id}/products/active`)
      .toPromise();
    for (const product of products) {
      if (product.start_date) {
        product.start_date = DateTime.fromISO(product.start_date).toJSDate();
      }
    }
    return products;
  }
}
