export class AuthModel {
  authToken: string;
  refreshToken: string;
  expires: Date;

  setAuth(auth: any) {
    this.authToken = auth.authToken;
    this.refreshToken = auth.refreshToken;
    this.expires = auth.expires;
  }
}
