import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/_modules/auth";

@Injectable({
  providedIn: "root",
})
export class AssessmentCycleService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  async testsByAssessmentCycleBySubject(id) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/report/${id}/tests/by-subjects`
      )
      .toPromise();
  }

  async getByGlobalAdmin() {
    return this.http
      .get<any>(`${environment.api.url}/assessment-cycles/get-by-global-admin`)
      .toPromise();
  }

  async getBySchool(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/assessment-cycles/get-by-school/${id}`)
      .toPromise();
  }

  async getByStudent(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/assessment-cycles/get-by-student/${id}`)
      .toPromise();
  }

  async getBySchoolGroup(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/get-by-school-group/${id}`
      )
      .toPromise();
  }

  async index() {
    const assessmentCycles = await this.http
      .get<any>(`${environment.api.url}/assessment-cycles`)
      .toPromise();
    for (const assessmentCycle of assessmentCycles) {
      // Ensure assessment cycle had global admin managed
      if (!assessmentCycle.global_admin_managed) {
        assessmentCycle.global_admin_managed = false;
      }
    }
    return assessmentCycles;
  }

  async byTerm() {
    const assessmentCycles = await this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycles-by-term`
      )
      .toPromise();
    return assessmentCycles;
  }

  async single(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle/${id}`
      )
      .toPromise();
  }

  async add(model: any) {
    return this.http
      .post(`${environment.api.url}/assessment-cycles`, model)
      .toPromise();
  }

  async edit(id, model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle/${id}`,
        model
      )
      .toPromise();
  }

  async delete(id: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle/${id}/delete`,
        null
      )
      .toPromise();
  }

  async indexAssessmentCycleTypes() {
    return await this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-types`
      )
      .toPromise();
  }

  async assessmentCycleDate(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-date/${id}`
      )
      .toPromise();
  }
  async singleAssessmentCycleType(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-types/assessment-cycle-type/${id}`
      )
      .toPromise();
  }

  async addAssessmentCycleType(model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-types`,
        model
      )
      .toPromise();
  }

  async editAssessmentCycleType(id, model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-types/assessment-cycle-type/${id}`,
        model
      )
      .toPromise();
  }

  async deleteAssessmentCycleType(id: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-types/assessment-cycle-type/${id}/delete`,
        null
      )
      .toPromise();
  }

  async indexAssessmentCycleCategories() {
    return await this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories`
      )
      .toPromise();
  }

  async getAssessmentCycleCategoriesForDashboard(groupName: string) {
    return await this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/get-assessment-cycle-categories-for-dashboard/${groupName}`
      )
      .toPromise();
  }

  async singleAssessmentCycleCategory(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories/assessment-cycle-category/${id}`
      )
      .toPromise();
  }

  async groupedByAssessmentCycleCategory(id: string) {
    return this.http
      .get<any>(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories/grouped-by-assessment-cycle-category/${id}`
      )
      .toPromise();
  }

  async addAssessmentCycleCategory(model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories`,
        model
      )
      .toPromise();
  }

  async editAssessmentCycleCategory(id, model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories/assessment-cycle-category/${id}`,
        model
      )
      .toPromise();
  }

  async deleteAssessmentCycleCategory(id: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories/assessment-cycle-category/${id}/delete`,
        null
      )
      .toPromise();
  }

  saveCategoriesOrder(model: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/assessment-cycle-categories/save-assessment-categories-order`,
        model
      )
      .toPromise();
  }

  async getGroupBySchool(assessmentCycleID: string) {
    return this.http
      .get(
        `${environment.api.url}/assessment-cycles/report/${assessmentCycleID}/groups-by-school`
      )
      .toPromise();
  }

  getTestCollections(assessmentCycleID: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/get-test-collections/${assessmentCycleID}`,
        null
      )
      .toPromise();
  }

  getSchoolsMarks(assessmentCycleID: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/get-school-marks/${assessmentCycleID}`,
        null
      )
      .toPromise();
  }

  getSchoolsMarksEYFS(assessmentCycleID: string) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/get-school-marks-eyfs/${assessmentCycleID}`,
        null
      )
      .toPromise();
  }

  marksByQuestion(assessmentCycleID: string, schools: any) {
    return this.http
      .post(
        `${environment.api.url}/assessment-cycles/marks-by-question/${assessmentCycleID}`,
        schools
      )
      .toPromise();
  }

  getSchoolSubjects() {
    return this.http
      .get(`${environment.api.url}/assessment-cycles/get-school-subjects/`)
      .toPromise();
  }

  canEdit(assessmentCycle) {
    let currentUser = this.authService.currentUserValue;
    // if assessment cycle is managed by global admin but not logged into global admin
    if (
      (currentUser.school || currentUser.school) &&
      assessmentCycle.global_admin_managed
    ) {
      return false;
    }
    // if logged in as school but assessment cycle is managed by school group
    if (
      currentUser.school &&
      assessmentCycle.school_groups &&
      assessmentCycle.school_groups.length
    ) {
      return false;
    }
    // If logged into global admin
    // or school and not managed by school group
    // or school group and not managed by global admin
    return true;
  }

  managedBy(assessmentCycle) {
    let currentUser = this.authService.currentUserValue;
    if (assessmentCycle.global_admin_managed) {
      return "askEddi";
    }
    if (assessmentCycle.school_groups && assessmentCycle.school_groups.length) {
      return assessmentCycle.school_groups[0].name;
    }
    return currentUser.school.name;
  }
}
