import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AssessmentCycleCategory } from "src/app/_models/assessment-cycle-category";
import { AssessmentCycleService } from "src/app/_services/assessment-cycles/assessment-cycle.service";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "src/app/_modules/auth";
import { ConditionalExpr } from "@angular/compiler";

@Component({
  selector: "app-modify-assessment-cycle-categories-modal",
  templateUrl: "./modify-assessment-cycle-categories-modal.component.html",
  styleUrls: ["./modify-assessment-cycle-categories-modal.component.css"],
})
export class ModifyAssessmentCycleCategoriesModalComponent implements OnInit {
  public assessmentCycleCategories: any = [];
  private assessmentCycleCategoryID: any = null;
  orderExists = false;
  public form: FormGroup;
  formSubmitted: any = false;
  view = "categories";
  loading = false;
  cols: any[];
  // Current User
  currentUser;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private assessmentCycleService: AssessmentCycleService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue;
    this.cols = [{ field: "name", header: "Name" }];
    this.sortAssessmentCycleCategories();
  }

  sortAssessmentCycleCategories() {
    this.assessmentCycleCategories.sort(function (a, b) {
      return a.order > b.order ? 1 : -1;
    });
    this.form = this.formBuilder.group({
      name: ["", Validators.required],
      order: ["", Validators.required],
    });
  }
  canEdit(assessmentCycleCategory) {
    if (this.currentUser.school) {
      if (
        assessmentCycleCategory.school &&
        assessmentCycleCategory.school._id === this.currentUser.school._id
      ) {
        return true;
      }
    }
    if (this.currentUser.school_group) {
      if (
        assessmentCycleCategory.school_group &&
        assessmentCycleCategory.school_group._id ===
          this.currentUser.school_group._id
      ) {
        return true;
      }
    }
    return false;
  }

  addCategory() {
    const order = this.assessmentCycleCategories.length + 1;
    this.view = "form";
    this.form.patchValue({ name: "", order: order });
  }

  editCategory(category) {
    if (!this.canEdit(category)) {
      return false;
    }
    this.view = "form";
    this.assessmentCycleCategoryID = category._id;
    this.form.patchValue({ name: category.name, order: category.order });
  }

  get f() {
    return this.form.controls;
  }

  async deleteCategory(category) {
    this.sortAssessmentCycleCategories();
    if (!this.canEdit(category)) {
      return false;
    }
    let confirmed;
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.title = "Assessment Cycle Category";
    confirmed = await modalRef.result;
    if (confirmed) {
      this.loading = true;
      await this.assessmentCycleService.deleteAssessmentCycleCategory(
        category._id
      );
      this.assessmentCycleCategories =
        await this.assessmentCycleService.indexAssessmentCycleCategories();
      this.loading = false;
    }
  }

  cancelAddEdit() {
    this.form.patchValue({ name: "" });
    this.assessmentCycleCategoryID = null;
    this.formSubmitted = false;
    this.view = "categories";
  }

  async submitAssessmentCycleCategory() {
    this.formSubmitted = true;
    this.orderExists = false;

    if (this.form.invalid) {
      return;
    }

    let assessmentCycleCategory: any = this.form.value;
    // for (let category of this.assessmentCycleCategories) {
    //   if (category.order === assessmentCycleCategory.order) {
    //     if (category._id && category._id != assessmentCycleCategory._id)
    //       this.orderExists = true;
    //     return;
    //   }
    // }

    this.loading = true;
    if (this.assessmentCycleCategoryID) {
      await this.assessmentCycleService.editAssessmentCycleCategory(
        this.assessmentCycleCategoryID,
        assessmentCycleCategory
      );
    } else {
      await this.assessmentCycleService.addAssessmentCycleCategory(
        assessmentCycleCategory
      );
    }

    this.loading = false;
    this.assessmentCycleCategories =
      await this.assessmentCycleService.indexAssessmentCycleCategories();
    this.sortAssessmentCycleCategories();
    this.cancelAddEdit();
  }

  async saveCategoryOrder() {
    let index = 1;
    for (let category of this.assessmentCycleCategories) {
      category.order = index;
      index++;
    }
    this.sortAssessmentCycleCategories();
    await this.assessmentCycleService.saveCategoriesOrder(
      this.assessmentCycleCategories
    );
    this.activeModal.close(true);
    return this.assessmentCycleCategories;
  }

  async submit() {
    await this.saveCategoryOrder();
    this.activeModal.close(true);
  }

  async close() {
    this.activeModal.close(false);
  }
}
