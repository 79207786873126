<div class="modal-header">
  <h4 class="modal-title">
    {{ title }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="bs-callout-danger callout-border-left p-1">
    <div [innerHTML]="body"></div>
  </div>
</div>
<div class="modal-footer">
  <button
    *ngIf="retry"
    type="button"
    class="btn grey btn-success"
    (click)="submit()"
  >
    Retry
  </button>
  <button type="button" class="btn grey btn-secondary" (click)="close()">
    Close
  </button>
</div>
