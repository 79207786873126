import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TableModule } from "primeng/table";
import { SubheaderModule } from "src/app/_metronic/partials/layout/subheader/subheader.module";
import { LoaderModule } from "../../_components/loader/loader.module";
import { AcademicYearSelectorModalComponent } from "./academic-year-selector-modal.component";

@NgModule({
  declarations: [AcademicYearSelectorModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    RouterModule.forChild([
      {
        path: "",
        component: AcademicYearSelectorModalComponent,
      },
    ]),
    SubheaderModule,
    TableModule,
    LoaderModule,
  ],
  exports: [RouterModule],
})
export class AcademicYearSelectorModalModule {}
