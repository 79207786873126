import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { DateTime } from "luxon";

export class LuxonDateFormatter extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  readonly DT_FORMAT = "dd/LL/yyyy";

  format(date: NgbDateStruct): string {
    if (date === null) {
      return "";
    }
    const mdt = DateTime.fromObject(date);
    if (!mdt.isValid) {
      return "";
    }
    return mdt.toFormat(this.DT_FORMAT);
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    let mdt = DateTime.fromFormat(value, this.DT_FORMAT);
    if (!mdt.isValid) {
      return null;
    }
    const dateObject = mdt.toObject();
    return mdt.isValid
      ? {
          year: dateObject.year,
          month: dateObject.month,
          day: dateObject.day,
        }
      : null;
  }
}
