import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AcademicYearService {
  constructor(private http: HttpClient) {}

  async index() {
    return await this.http
      .get<any>(`${environment.api.url}/academic-years`)
      .toPromise();
  }

  async single(id: string) {
    return this.http
      .get<any>(`${environment.api.url}/academic-years/academic-year/${id}`)
      .toPromise();
  }

  async add(model: any) {
    return this.http
      .post(`${environment.api.url}/academic-years`, model)
      .toPromise();
  }

  async edit(id, model: any) {
    return this.http
      .post(`${environment.api.url}/academic-years/academic-year/${id}`, model)
      .toPromise();
  }

  async delete(id: string) {
    return this.http
      .post(
        `${environment.api.url}/academic-years/academic-year/${id}/delete`,
        null
      )
      .toPromise();
  }

  checkDateExists(data: any) {
    return this.http
      .post(`${environment.api.url}/academic-years/check-date-exists`, data)
      .toPromise();
  }

  getCurrentAcademicYear() {
    return this.http
      .get(`${environment.api.url}/academic-years/current`)
      .toPromise();
  }

  getPreviousAcademicYear() {
    return this.http
      .get(`${environment.api.url}/academic-years/previous`)
      .toPromise();
  }

  getCurrentSchoolAcademicYear(id) {
    return this.http
      .get(`${environment.api.url}/academic-years/current-for-school/${id}`)
      .toPromise();
  }

  getPreviousSchoolAcademicYear(id) {
    return this.http
      .get(`${environment.api.url}/academic-years/previous-for-school/${id}`)
      .toPromise();
  }

  getCurrentSchoolGroupAcademicYear(id) {
    return this.http
      .get(
        `${environment.api.url}/academic-years/current-for-school-group/${id}`
      )
      .toPromise();
  }

  getPreviousSchoolGroupAcademicYear(id) {
    return this.http
      .get(
        `${environment.api.url}/academic-years/previous-for-school-group/${id}`
      )
      .toPromise();
  }
}
