import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DynamicAsideMenuConfig } from "../../../_layout/_configs/dynamic-aside-menu.config";

const emptyMenuConfig = {
  items: [],
};

@Injectable({
  providedIn: "root",
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  constructor() {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.loadMenu();
  }

  initMenuContext() {
    const currentUser = sessionStorage.getItem("current_user")
      ? JSON.parse(sessionStorage.getItem("current_user"))
      : null;
    if (!currentUser) {
      return null;
    }
    if (currentUser.school) {
      return "school";
    }
    if (currentUser.school_group) {
      return "school_group";
    }
    return "global_admin";
  }

  // Here you able to load your menu from server/data-base/localStorage
  // Default => from DynamicAsideMenuConfig
  loadMenu() {
    const menu = this.initMenuContext();
    this.setMenu(DynamicAsideMenuConfig[menu]);
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
