import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DownloadUploadMarkbookScoresModalComponent } from "./download-upload-markbook-scores-modal.component";
import { TableModule } from "primeng/table";

@NgModule({
  declarations: [DownloadUploadMarkbookScoresModalComponent],
  imports: [CommonModule, TableModule],
  exports: [DownloadUploadMarkbookScoresModalComponent],
})
export class DownloadUploadMarkbookScoresModalModule {}
