<div class="container loading text-center">
  <div class="eddi-logo">
    <div class="top-circle"></div>
    <div class="top-rectangle">
      <div class="first"></div>
      <div class="middle"></div>
      <div class="end"></div>
    </div>
    <div class="middle-rectangle">
      <div class="first"></div>
      <div class="middle"></div>
      <div class="end"></div>
    </div>
    <div class="bottom-rectangle">
      <div class="first"></div>
      <div class="middle"></div>
      <div class="end"></div>
    </div>
  </div>
</div>
