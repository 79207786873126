import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(sessionStorage.getItem("current_user"));
    let newHeaders: any = request.clone();

    if (currentUser && currentUser.school && currentUser.school._id) {
      newHeaders = newHeaders.clone({
        headers: newHeaders.headers.set("School", currentUser.school._id),
      });
    }
    if (
      currentUser &&
      currentUser.school_group &&
      currentUser.school_group._id
    ) {
      newHeaders = newHeaders.clone({
        headers: newHeaders.headers.set(
          "SchoolGroup",
          currentUser.school_group._id
        ),
      });
    }
    if (
      currentUser &&
      currentUser.academic_year &&
      currentUser.academic_year._id
    ) {
      newHeaders = newHeaders.clone({
        headers: newHeaders.headers.set(
          "AcademicYear",
          currentUser.academic_year._id
        ),
      });
    }
    if (newHeaders) {
      return next.handle(newHeaders);
    }
    return next.handle(request);
  }
}
