import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";

Sentry.init({
	dsn: environment.sentry_dsn,
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [environment.api.url],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: environment.traces_sample_rate,
	// Session Replay
	replaysSessionSampleRate: environment.replays_session_sample_rate,
	replaysOnErrorSampleRate: environment.replays_on_error_sample_rate,
	environment: environment.type,
	sendClientReports: false,
	beforeSend(event) {
		// Filter out handled errors
		if (event.exception?.values[0]?.mechanism?.handled) {
			// This error was marked as handled, so we don't want to send it to Sentry
			return null;
		}
		return event;
	},
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
