import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
@Injectable({
  providedIn: "root",
})
export class DateHelperService {
  constructor() {}

  formatDate(date) {
    if (date instanceof Date) {
      date = date.toISOString();
    }
    return DateTime.fromISO(date).toFormat("dd/LL/yyyy");
  }

  formatTime(date) {
    if (date instanceof Date) {
      date = date.toISOString();
    }
    return DateTime.fromISO(date).toFormat("HH:mm");
  }

  dateToObject(date: any) {
    if (date instanceof Date) {
      date = date.toISOString();
    }
    return DateTime.fromISO(date).toObject();
  }

  objectToDate(dateObject: DateObject) {
    return DateTime.fromObject(dateObject).toISO();
  }

  toJSDate(date) {
    if (date instanceof Date) {
      return date;
    }
    date = DateTime.fromISO(date);
    if (date.isValid) {
      return date.toJSDate();
    }
    return null;
  }

  // getYear(date) {
  //   return moment(date).year();
  // }
}

class DateObject {
  year: number;
  month: number;
  day: number;
}
